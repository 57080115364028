<template>
  <div class="fiat">
    <div class="supheader bg-stellantis-500">
      <div class="container flex mx-auto flex-wrap w-10/12 p-1 items-center">
        <div class="w-full md:w-1/2 mb-2 md:mb-0">
          <img class="mx-auto md:mx-0" src="img/logo/STELLANTIS&YOU_logo_white_350x60.png" alt="" style="max-height: 50px">
        </div>
        <div class="w-full md:w-1/2 text-white">
          <p class="text-sm text-center md:text-left"><strong class="font-extrabold">BOULOGNE BILLANCOURT</strong><br>65 RUE DU CHATEAU, 92100 BOULOGNE BILLANCOURT</p>
        </div>
      </div>
    </div>
   <!--  <div class="w-full py-2">
      <img src="img/logo/logo-fiat.png" alt="" class="w-20 mx-auto">
    </div> -->
    <div class="header w-full relative bg-gradient-to-b md:bg-gradient-to-r from-fiat-590 to-fiat-510">
      <div class="container flex mx-auto flex-wrap w-10/12 _fond items-center pb-8">
        <div class="w-full md:w-3/5 p-2">
          <img src="img/logo/logo-fiat.png" alt="" class="w-20 mb-4 mx-auto md:mx-0">
          <font class="text-4xl md:text-4xl text-fiat-500 font-extrabold block text-center md:text-left text-shadow">RÉDUISEZ VOS ÉMISSIONS &amp;&nbsp;ILLUMINEZ TOUS VOS TRAJETS&nbsp;!</font>
          <img class="w-full" :src="img" alt="">
        </div>
        <div class="w-full md:w-2/5 text-white text-center md:text-left p-2">
          <div class="font-extrabold">
            <!--<Tampon>Disponibilité<br>immédiate</Tampon>-->
            <font class="text-white">Nouvelle Fiat 500 100% Électrique</font>
            <div class="text-2xl">
              à partir de <font class="text-4xl">139&nbsp;€</font> <font class="text-2xl">/mois</font><sup class="inline-flex transform -translate-y-1.5 font-medium" style="font-size: 0.8rem;">(1)</sup><br>
              soit moins de<br><font class="text-6xl">5&nbsp;€</font> <font class="text-5xl">/jour</font>
            </div>
          </div>
          <Hr class="hidden md:inline-block"/>
          <p class="mb-4">LLD 36 mois <font class="font-extrabold">sans condition de reprise</font>. <br>Après apport de 2 500 €, déduction faite du bonus écologique.</p>
          <div class="flex flex-col">
            <Cta cls="btn1" marque="fiat" class="mb-2"><font class="font-extrabold text-2xl uppercase">Profitez de cette offre</font></Cta>
            <Cta cls="btn1" marque="fiat" class="mb-2"><font class="font-extrabold uppercase">En savoir plus</font></Cta>
          </div>
        </div>
      </div>
    </div>
    <div class="h-12"></div>
    <section class="container flex flex-col text-center mx-auto flex-wrap w-10/12 mb-8">
      <div class="w-full">
        <div class="flex flex-wrap justify-center items-center mb-8">
          <img src="img/logo/badge500e.png" class="inline-block h-16" alt="">
        </div>
        <p class="uppercase">
          <font class="font-light text-fiat-500 text-3xl">80% des Fiat&nbsp;500 vendues sont en 100%&nbsp;électrique.</font><br>
          <font class="font-extrabold text-fiat-500 text-3xl md:text-5xl">Alors pourquoi pas vous&nbsp;?</font>
        </p>
      </div>
    </section>
    <section class="container flex flex-col text-center mx-auto flex-wrap w-10/12 mb-8">
      <div class="w-full">
        <div class="flex flex-wrap w-full items-center justify-center">
          <div class="w-full md:w-5/12 text-left p-2">
            <p class="mt-4">
              La Fiat&nbsp;500 100%&nbsp;électrique séduit par son design toujours aussi jovial et&nbsp;lumineux&nbsp;! Sa motorisation 100%&nbsp;électrique la rend encore plus maline et&nbsp;tellement responsable&nbsp;!<br>C’est l’objet d’un plaisir idéal pour tous vos déplacements quotidiens grâce à une autonomie électrique allant jusqu’à 320&nbsp;km* en WLTP avec la&nbsp;batterie haute capacité.
            </p>
          </div>
          <div class="w-full md:w-5/12 p-2">
            <img src="img/divers/500bev-ElectricFeatures-one-pedal-driving-Desktop-480x435.gif" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="w-full bg-fiat-200">
      <div class="container flex flex-col text-center mx-auto flex-wrap w-8/12 md:w-1/2 my-12 py-12">
        <font class="w-full text-left text-3xl font-bold pb-4 block text-center">En conduite urbaine, l’autonomie atteint de 257 à&nbsp;460&nbsp;km* selon le modèle&nbsp;choisi.</font>
        <p>De quoi faire sans mal plusieurs fois le tour de Paris avant de retourner brancher votre Fiat 500 Électrique au bureau, à votre domicile ou sur une borne publique !</p>
        <Cta cls="btn1" marque="fiat" class="my-5">JE REJOINS LE CLUB 500e</Cta>
      </div>
    </section>
    <section class="w-full">
      <div class="container flex text-center mx-auto flex-wrap w-8/12 my-12 py-12">
        <font class="w-full text-left text-3xl font-bold pb-4 block text-center">Selon vos envies de confort et de technologies</font>
        <p class="w-full mb-6">Équipements de série ou en option selon la finition et vos envies.</p>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4 text-left">
          <font class="font-extrabold block">Confort & Technologie à bord</font>
          <ul class="list-disc pl-5">
            <li>Capteur de pluie</li>
            <li>Climatisation automatique</li>
            <li>Connectivité Apple CarPlay<sup class="text-sm">®</sup> / Android&nbsp;Auto™</li>
            <li>Radio Digital Audio Broadcast (DAB)</li>
            <li>Frein de stationnement électrique</li>
            <li>Limiteur de vitesse intelligent</li>
            <li>Ouverture et démarrage sans clé</li>
            <li>Radar de recul</li>
            <li>Régulateur de vitesse</li>
            <li>Système Uconnect™ avec écran 10,25" et navigation</li>
            <li>Volant gainé en éco-cuir</li>
          </ul>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4 text-left">
          <font class="font-extrabold block">Sécurité</font>
          <ul class="list-disc pl-5">
            <li>Alerte de franchissement de ligne</li>
            <li>Appel d’urgence e-Call</li>
            <li>Capteur de luminosité</li>
            <li>Détecteur de fatigue</li>
            <li>Freinage automatique d’urgence</li>
            <li>Reconnaissance des panneaux de signalisation</li>
          </ul>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4 text-left">
          <font class="font-extrabold block">Univers électrique</font>
          <ul class="list-disc pl-5">
            <li>Batterie jusqu’à 42&nbsp;kW</li>
            <li>Câble mode 2 (recharge à domicile</li>
            <li>Recharge rapide jusqu’à 50 ou 85&nbsp;k</li>
            <li>Services Uconnect™</li>
          </ul>
          <img src="img/divers/App-FIAT-desktop-570x322.jpg" class="mt-5" alt="">
        </div>
      </div>
    </section>
    
    <Carousel :content="exterieur" titre="Tour d’horizon extérieur" />
    <div class="h-12"></div>
    <Carousel :content="interieur" titre="Prenez place à bord" />
    <div class="w-10/12 md:w-1/2 mx-auto text-center mt-20 mb-32">
      <Cta cls="btn1" marque="fiat">JE VEUX EN PROFITER !</Cta><br><br>
      <a href="./stock/Brochure_catalogue_500_electrique.pdf" target="_blank" class="text-fiat-500 hover:underline">Je télécharge la brochure Fiat 500e</a>
    </div>
    <section class="w-full bg-fiat-200">
      <div class="container flex flex-col text-center mx-auto flex-wrap w-8/12 md:w-1/2 my-12 py-12">
      <img src="img/logo/STELLANTIS-and-you-logo-blue.png" alt="" class="w-64 mx-auto mb-12">
        <p><strong>Fiat Stellantis &You Boulogne</strong> (anciennement appelé Fiat Motor Village Boulogne) est une garantie de professionnalisme, de qualité et de passion pour le monde de l’automobile.<br><br>Chez <strong>Fiat Stellantis &You Boulogne</strong>, nous vous présentons un large éventail de véhicules neufs. Vous y trouverez un personnel attentionné et compétent à l’écoute de vos besoins. Il vous guidera dans vos choix, pour vous recommander un financement sur mesure et configurer le véhicule de vos souhaits.<br><br><strong>Stellantis &You Boulogne</strong> propose également des offres de financement aux particuliers et aux professionnels sur les marques <strong>Alfa Romeo, Jeep, Abarth et Fiat Professional</strong>.
        </p>
      </div>
    </section>
    <div class="w-10/12 md:w-1/2 mx-auto text-center mt-20 mb-32">
      <Lom conso="0"/>
    </div>
    <Footer marque="stellantis">
      <p class="text-xs">
        (1) Exemple pour Nouvelle 500 Action dans la limite des stocks disponibles au tarif conseillé du 01/07/2022 <strong>en location longue durée sur 36&nbsp;mois et 30&nbsp;000&nbsp;km maximum soit 36&nbsp;loyers mensuels de 139&nbsp;€&nbsp;TTC après apport de 8&nbsp;500&nbsp;€&nbsp;TTC ramené à 2&nbsp;500&nbsp;€ après déduction du bonus écologique de 6&nbsp;000&nbsp;€</strong>. Offre sans condition de reprise, non cumulable avec d’autres offres en cours, réservée aux particuliers et <strong>valable jusqu’au 30/09/2022</strong> dans le réseau Fiat participant. Sous réserve d’acceptation de votre dossier par Leasys France, SAS - 1 rue Victor Basch - 91300&nbsp;Massy - RCS&nbsp;Évry 413&nbsp;360&nbsp;181.<br><strong>Modèle présenté</strong>&nbsp;: version spécifique sur base finition Icône Plus.
        <br><strong>Gamme Nouvelle Fiat&nbsp;500e&nbsp;: Consommations min./max. (Wh/km)&nbsp;: de 130 à&nbsp;149&nbsp;; émissions de CO<sub>2</sub> (g/km)&nbsp;: 0 à&nbsp;l’usage. Jusqu’à 320&nbsp;km d’autonomie électrique en WLTP et jusqu’à 460&nbsp;km d’autonomie électrique en ville en&nbsp;WLTP.</strong>
      </p>
    </Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Carousel from '@/components/Carousel.vue'
import Hr from '@/components/Hr.vue'
import Tampon from '@/components/Tampon.vue'
import Advantage from '@/components/Advantage.vue'
import Lom from '@/components/Lom.vue'
export default {
  props: {
    contrat: String,
    img: String,
    offre: String,
    title: String,
  },
  components: {
    Hr,
    Tampon,
    Footer,
    Carousel,
    Advantage,
    Lom
  },
  data(){
    return{
      brand: 'leasys',
      avantages: [
        {img: 'img/icons/thumb1.png', text: "100% digital<br>De la réservation au paiement"},
        {img: 'img/icons/thumb2.png', text: "Recharges gratuites dans tous les Leasys Mobility Stores"},
        {img: 'img/icons/thumb3.png', text: "Sans engagement<br>Vous arrêtez quand vous voulez"},
        {img: 'img/icons/thumb4.png', text: "1 500 km/mois inclus"},
        {img: 'img/icons/thumb5.png', text: "Assurance tous risques incluse"},
        {img: 'img/icons/thumb6.png', text: "Entretien et assistance 24/7"},
      ],
      exterieur: [
        {
          titre: 'Séduisante…',
          img: 'img/cars/500e-8.jpg'
        },
        {
          titre: '… à chaque point de vue…',
          img: 'img/cars/500e-7.jpg'
        },
        {
          titre: '… et sous tous les angles.',
          img: 'img/cars/500e-4.jpg'
        },
        {
          titre: 'Trappe parfaitement intégrée pour un style préservé.',
          img: 'img/cars/500e-9.jpg'
        }
      ],
      interieur: [
        {
          titre: 'Rencontre au sommet entre design intemporel et technologie d’avant&#8209;garde.',
          img: 'img/cars/500e-5.jpg'
        },
        {
          titre: 'Tellement plus d’espace qu’il n’y paraît.',
          img: 'img/cars/500e-6.jpg'
        },
        {
          titre: 'Une petite citadine très branchée.',
          img: 'img/cars/500e-1.jpg'
        },
        {
          titre: 'Pratique et malin pour rester connecté à vos applis préférées.',
          img: 'img/cars/500e-3.jpg'
        },
        {
          titre: 'Digital jusqu’au bout des ongles.',
          img: 'img/cars/500e-2.jpg'
        }
      ]
    }
  },
  mounted(){
    this.$store.commit('setForm', {id: '220734187781360', model: '500e', carImg: this.img, carName: this.title, confirmID: 1})
  }
}
</script>

<style lang="scss">
.header{
  //background-color: var(--color7);
  ._fond{
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.text-shadow{
  text-shadow: 1px 1px 13px rgb(0 0 0 / 24%);
}
</style>